<template>
  <div class="home background vertical centered">
    <div class="mcont horizontal centered">
      <div class="vertical left text-cont">
        <h1 style="margin: -0.25rem">
          <span style="color: #2113a6">Soxen</span>
          <span style="color: #dd0307">Tec</span>
        </h1>
        <h2>
          <span style="color: green"> Grüne Energie</span>
          – nachhaltig und sauber
        </h2>
        <h4>
          Wir realisieren Ihr Projekt für eine
          <span style="color: green">CO2-freie </span>
          Energiegewinnung.
        </h4>
        <!-- <v-btn
          color="primary"
          rounded
          large
          style="margin: 1.5rem 0 !important"
        >
          <v-icon>mdi-mail</v-icon>
          <span style="margin: 0 0.5rem">Kontakt</span>
        </v-btn> -->
        <div
          class="horizontal left wrapped"
          style="
            padding: 1rem 0;
            gap: 0.5rem;
            font-size: 1.25rem;
            flex-direction: row;
          "
        >
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon> Energieberatung
          </v-chip>
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon> Projektplanung
          </v-chip>
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon> Photovoltaikanlagen
          </v-chip>
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon> Lüftungen
          </v-chip>
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon> Wärmepumpen
          </v-chip>
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon>
            Erd-/Luftkollektoren
          </v-chip>
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon> Eis-Energiespeicher
          </v-chip>
          <v-chip color="primary" outlined class="horizontal centered">
            <v-icon>mdi-check</v-icon> Kalt-Netze
          </v-chip>
        </div>
      </div>

      <div class="vertical pic-cont centered hidden-sm-and-down">
        <v-img
          class="m-pic-hover"
          width="35vw"
          height="35vw"
          max-width="45vh"
          max-height="45vh"
          style="
            border-radius: 50%;
            z-index: 2;
            margin-left: 50% !important;
            background-color: whitesmoke;
          "
          @click="solarIndex++"
          :src="getSolarPicFor(solarIndex)"
        >
        </v-img>
        <v-img
          class="m-pic-hover"
          width="30vw"
          height="30vw"
          max-width="40vh"
          max-height="40vh"
          style="
            border-radius: 50%;
            margin-top: -20%;
            background-color: whitesmoke;
          "
          @click="storeIndex++"
          :src="getElsePicFor(storeIndex)"
        >
        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},

  data() {
    return {
      solarIndex: 0,
      storeIndex: 0
    };
  },

  mounted() {
    this.timePic();
  },

  methods: {
    timePic() {
      setTimeout(() => {
        this.solarIndex++;
        this.storeIndex++;
        this.timePic();
      }, 5000);
    },
    getSolarPicFor(pos) {
      let image;
      try {
        image = require("@/assets/imgs/solarpics/solar/" + pos + ".webp");
      } catch (e) {
        this.solarIndex = 0;
        try {
          image = require("@/assets/imgs/solarpics/solar/" + pos + ".webp");
        } catch (e) {
          e;
        }
        e;
      }
      return image;
    },
    getElsePicFor(pos) {
      let image;
      try {
        image = require("@/assets/imgs/solarpics/else/" + pos + ".webp");
      } catch (e) {
        this.storeIndex = 0;
        try {
          image = require("@/assets/imgs/solarpics/else/" + pos + ".webp");
        } catch (e) {
          e;
        }
        e;
      }
      return image;
    },
  },
};
</script>

<style lang="scss" >
.background {
  background-blend-mode: overlay;
  background-repeat: no-repeat !important;
  background-position: center !important;

  // background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>

<style lang="scss" scoped>
.home {
  width: 100vw !important;
  min-height: 90vh;
  // max-height: 90vh;

  padding: 0 !important;
  margin: 0 !important;

  background: url("~@/assets/imgs/solarpics/solar/2.webp"),
    rgba(245, 245, 245, 0.95);

  @media screen and (max-width: 1000px) {
    padding-top: 3rem !important;
  }
}

.m-pic-hover {
  &:hover {
    z-index: 4;

    // width: 33vw !important;
    // height: 33vw !important;
    // max-width: 60vh !important;
    // max-height: 60vh !important;
    font-size: 1.5em;
    transform: scale(1.1) translateX(-10%) !important;
  }
}

.mcont {
  width: 100vw !important;
  margin: auto;

  .text-cont {
    width: 50vw !important;
    padding-left: 2% !important;
    @media screen and (max-width: 600px) {
      padding: 0 2rem !important;
    }
  }
  .pic-cont {
    width: 40vw !important;
    margin: 0 0 0 -5%;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    * {
      align-items: center !important;
      justify-content: center !important;
    }

    .text-cont {
      width: 90vw !important;
      margin: auto;
      * {
        text-align: center !important;
      }
    }
    .pic-cont {
      width: 90vw;
    }
  }
}
</style>
