<template>
  <v-app id="app">
    <v-expand-transition>
      <v-app-bar
        app
        v-if="$router.currentRoute.path == '/'"
        dark
        color="primary"
        style="width: 100vw"
      >
        <v-app-bar-nav-icon @click="scrollToHash('Home')">
          <v-icon large color="#DD0307">mdi-chess-rook</v-icon>
        </v-app-bar-nav-icon>
        <v-tabs
          v-model="selectedTab"
          color="white"
          slider-color="#DD0307"
          show-arrows
        >
          <v-tab @click="scrollToHash('Home')">
            <!-- 
              
              <v-img
              max-width="32"
              max-height="32"
              color="white"
              style="margin: 0 0.5rem"
            >
              <v-icon large color="secondary">mdi-chess-rook</v-icon>
            </v-img> 

            -->
            SoxenTec
            <span style="font-size: 0.75rem; margin-left: 0.5rem"> UG </span>
          </v-tab>
          <v-tab
            v-for="tab in tabs"
            :key="tab.key"
            @click="scrollToHash(tab.routerPathName)"
          >
            <!-- :to="{ name: tab.routerPathName }" -->
            {{ tab.title }}
          </v-tab>
          <v-spacer></v-spacer>

          <v-tab @click="scrollToHash('Contact')">
            <!-- :to="{ name: 'Contact' }" -->
            Kontakt
            <v-icon>mdi-account</v-icon>
          </v-tab>
        </v-tabs>
      </v-app-bar>
    </v-expand-transition>

    <v-main style="padding-right: 1rem; margin-bottom: 3rem">
      <router-view />
    </v-main>

    <v-footer
      class="vertical gapped"
      style="
        color: white;
        background-color: #2113a6;
        position: fixed !important;
        bottom: 0;
        width: calc(100%);
        z-index: 10;
      "
    >
      <div class="horizontal wrapped centered" style="flex-direction: row">
        <v-btn color="white" rounded text href="tel:+492022838850">
          <v-icon>mdi-phone</v-icon>
          <span class="hidden-sm-and-down">+49 202 2838 850</span>
        </v-btn>
        <v-btn color="white" rounded text href="tel:+491739217775">
          <v-icon>mdi-cellphone</v-icon>
          <span class="hidden-sm-and-down"> +49 173 921 7775 </span>
        </v-btn>
        <v-btn color="white" rounded text href="mailto:solar@soxentec.de">
          <v-icon>mdi-email</v-icon>
          <span class="hidden-sm-and-down">solar@soxentec.de</span>
        </v-btn>
      </div>
      <span
        style="
          position: absolute;
          bottom: 4px;
          right: 0.5rem;
          font-size: 0.8em;
          width: auto;
        "
      >
        Made by Florus
      </span>
      <v-btn
        text
        rounded
        dark
        small
        to="impressum"
        style="
          position: absolute;
          bottom: 4px;
          left: 0rem;
          font-size: 0.8em;
          width: auto;
        "
      >
        Impressum
      </v-btn>
    </v-footer>

    <!-- <v-dialog
      v-model="loginDial"
      scrollable
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card style="text-align: left !important">
        <v-card-title primary-title> Login </v-card-title>
        <v-card-subtitle> Micom only </v-card-subtitle>
        <v-card-text>
          <v-text-field
            style="margin: 0.5rem"
            label="Pin"
            password
            outlined
            type="password"
            v-model="enteredPin"
            hide-details
          >
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="checkPW">Enter</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      selectedTab: 0,
      // loginDial: !this.pin,
      // enteredPin: "",
      tabs: [
        {
          key: 0,
          title: "Beratung",
          routerPathName: "Beratung",
        },
        {
          key: 1,
          title: "Planung",
          routerPathName: "Planung",
        },
        {
          key: 2,
          title: "Projekte",
          routerPathName: "Projekte",
        },
        {
          key: 3,
          title: "Wir",
          routerPathName: "We",
        },
        // {
        //   key: 4,
        //   title: "Kontakt",
        //   routerPathName: "Contact",
        // },
      ],
    };
  },

  methods: {
    mouseOver() {
      this.showInfos = true;
    },
    mouseOut() {
      this.showInfos = false;
    },
    // checkPW() {
    //   this.$store.state.pin = this.enteredPin;
    //   if (this.pin) this.loginDial = false;
    // },
    scrollToHash(id) {
      if (id) {
        // const yOffset = window.innerHeight * -0.15;
        const scrollToView = document.getElementById(id);
        // const y = scrollToView.getBoundingClientRect().top + window.pageYOffset;
        // yOffset;

        scrollToView &&
          setTimeout(function () {
            // window.scrollTo({ top: y, behavior: "smooth" });
            scrollToView.scrollIntoView({
              behavior: "smooth",
            });
          }, 200);
      }
    },
  },

  computed: {},
};
</script>



<style lang="scss">
@import "./assets/scss/basics.scss";
@import "./assets/scss/main.scss";
* {
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  background-color: whitesmoke;

  width: 100vw;
  height: 100vh;

  font-size: 1rem;

  overflow-x: hidden;

  @media screen and (min-width: 2000px) {
    font-size: 1.25rem;
  }
}

* {
  transition: all 0.5s ease; //cubic-bezier(0.22, 1, 0.36, 1);
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    // color: #2c3e50;

    &.router-link-exact-active {
      // color: #42b983;
    }
  }
}

.btn {
  .text {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  :hover {
    .text {
      visibility: visible;
      opacity: 1;
    }
  }
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 16px;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
  background-color: transparent;
  background: transparent;
  border-radius: 10px;

  margin: 25vh 0;
}

*::-webkit-scrollbar-thumb {
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(201, 142, 96, 1) 0%,
    rgba(189, 98, 70, 1) 18%,
    rgba(199, 55, 55, 1) 48%,
    rgba(151, 16, 16, 1) 100%
  );
  border-radius: 10px;
}
</style>
