<template>
  <div class="mcont vertical centered gapped">
    <!--  -->
    <div class="horizontal centered" style="gap: 5%">
      <div
        class="vertical left gapped"
        style="text-align: left; margin-right: -5%; padding: 0 10%"
      >
        <h1 style="margin: -1rem 0">Über uns</h1>
        <p>
          Unser Team aus erfahrenen Solar-Installateuren und Planern betreut Ihr
          Projekt von der Idee über die Installation bis zur Wartung. Dabei
          steht uns ein Netzwerk zuverlässiger und kompetenter Spezialisten zur
          Verfügung, die Ihre Anlage mit uns gemeinsam realisieren.
        </p>
        <h3>Unsere Partner:</h3>
        <div class="vertical gapped" style="font-size: 1.25rem">
          <li>enlop GmbH</li>
          <li>esar Energy Solution Arnold Rehbronn Gbr</li>
          <li>HSL Solar GmbH</li>
          <li>Metternich Haustechnik GmbH</li>

          <!-- <v-img width="120" :src="require('@/assets/logo.png')" />
          <v-img width="120" :src="require('@/assets/logo.png')" />
          <v-img width="120" :src="require('@/assets/logo.png')" />
          <v-img width="120" :src="require('@/assets/logo.png')" /> -->
        </div>
      </div>
      <v-img class="big-picture" :src="svg" contain />
    </div>
  </div>
</template>
<script>
import svg from "@/assets/imgs/ilus/undraw_team_re_0bfe.svg";
export default {
  props: [],
  data() {
    return {
      //
      svg: svg,
    };
  },
  methods: {
    //
  },
  computed: {
    //
  },
};
</script>
<style lang="scss" scoped>
//
.mcont {
  width: 100vw;
  padding: 3rem 0;
  min-height: 100vh;

  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background: #f5f5f590;
}
</style>
