<template>
  <div class="mcont vertical centered gapped">
    <!--  -->
    <!-- <h1 style="margin: -1rem 0">Kontakt</h1> -->
    <v-img
      min-width="25vh"
      min-height="25vh"
      max-width="25vh"
      max-height="25vh"
      :src="require('@/assets/imgs/profile.webp')"
      style="
        background-color: white;
        border: 2px solid #2113a6;
        border-radius: 50%;
      "
    >
      <!-- <v-icon size="23vh" style="margin: auto">mdi-account</v-icon> -->
    </v-img>
    <h2>Andreas Bordon-Philipp</h2>
    <div
      class="horizontal centered wrapped gapped"
      style="
        width: 90vw !important;
        text-align: left;
        margin-right: -5%;
        padding: 0 2.5%;
      "
    >
      <div class="vertical centered gapped">
        <h3>Telefon</h3>
        <v-btn color="primary" rounded href="tel:+492022838850">
          <v-icon>mdi-phone</v-icon>
          +49 202 2838 850
        </v-btn>
      </div>
      <div class="vertical centered gapped">
        <h3>Handy</h3>
        <v-btn color="primary" rounded href="tel:+491739217775">
          <v-icon>mdi-cellphone</v-icon>
          +49 173 921 7775
        </v-btn>
      </div>
      <div class="vertical centered gapped">
        <h3>E-Mail</h3>
        <v-btn color="primary" rounded href="mailto:solar@soxentec.de">
          <v-icon>mdi-email</v-icon>
          solar@soxentec.de
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import svg from "@/assets/imgs/ilus/undraw_contact_us_re_4qqt.svg";
// import { mdiAccount } from "@mdi/js";

export default {
  props: [],
  data() {
    return {
      //
      svg: svg,
      // profilePic: mdiAccount,
    };
  },
  methods: {
    //
  },
  computed: {
    //
  },
};
</script>
<style lang="scss" scoped>
//
.mcont {
  width: 100vw;
  padding: 3rem 0 10rem 0;
  min-height: 90vh;

  background: url("~@/assets/imgs/ilus/undraw_contact_us_re_4qqt.svg"),
    rgba(245, 245, 245, 0.8);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>
