<template>
  <div class="mcont background vertical centered gapped">
    <!--  -->
    <div class="horizontal centered" style="gap: 5%">
      <div
        class="vertical left gapped"
        style="text-align: left; margin-right: -5%; padding: 0 10%"
      >
        <h1 style="margin: -1rem 0">Beratung</h1>
        <h2>Solaranlagen & Energiespeicher</h2>
        <p style="margin: 0">
          Die optimale Nutzung solarer Energie setzt eine hohe Fachkompetenz und
          Erfahrung voraus. Schließlich sollen die verfügbaren Flächen optimal
          für den Energieertrag genutzt werden können. Vertrauen Sie unserem
          Know-How, das wir in zahlreichen Projekten aufgebaut und angewandt
          haben. Gern zeigen wir Ihnen vorab, wie Ihre grüne Energiegewinnung
          aussehen kann.
        </p>
        <v-chip-group mandatory>
          <v-chip
            large
            color="primary"
            @click="
              selectedChip == 0 ? (selectedChip = -1) : (selectedChip = 0)
            "
            :outlined="selectedChip != 0"
            style="padding: 0 1.5rem"
          >
            Solaranlagen
          </v-chip>
          <v-chip
            large
            color="primary"
            @click="
              selectedChip == 1 ? (selectedChip = -1) : (selectedChip = 1)
            "
            :outlined="selectedChip != 1"
            style="padding: 0 1.5rem"
          >
            Energiespeicher
          </v-chip>
        </v-chip-group>
        <v-expand-transition>
          <div class="vertical wrapped" v-if="selectedChip == 0">
            <h3>Solare Stromerzeugung</h3>
            <p>
              Wir finden überall einen geeigneten Platz für unsere
              hocheffizienten Solarmodule:
            </p>
            <li style="margin-top: -1rem">
              Auf Dächern von Wohnhäusern und Gewerbebetrieben
            </li>
            <li>Fassaden</li>
            <li>Freiflächen</li>
            <p>
              Der so erzeugte elektrische Strom wird in das Hausnetz zum eigenen
              Verbrauch eingeleitet, Überschüsse können gegen Vergütung ins
              öffentliche Netz eingespeist werden.
            </p>
            <!-- <v-img
              class="hidden-md-and-up"
              style="
                border-radius: 2rem;
                background-color: whitesmoke;
                border: 2px solid #2113a6;
              "
              min-width="160"
              min-height="160"
              :src="svg"
              contain
            /> -->
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="selectedChip == 1">
            <h3>Energiespeicherung</h3>
            <p>
              Während der selbst verbrauchte Solarstrom die beste Wahl ist, ist
              eine Netzeinspeisung zu geringeren Tarifen weniger lukrativ. Das
              lässt sich durch Stromspeicher lösen, die die Energie auch dann
              zum Eigenverbrauch liefern können, wenn die Anlage nicht genug
              Energie liefern kann – z.B. nachts. Wir setzen Energiespeicher
              führender Hersteller mit höchstem Wirkungsgrad ein, damit auch
              jeder Sonnenstrahl gut genutzt werden kann.
            </p>
          </div>
        </v-expand-transition>
      </div>
      <v-img class="big-picture" :src="svg" contain />
    </div>
  </div>
</template>
<script>
import svg from "@/assets/imgs/ilus/undraw_group_chat_re_frmo.svg";
export default {
  props: [],
  data() {
    return {
      //
      svg: svg,
      selectedChip: -1,
    };
  },
  methods: {
    //
  },
  computed: {
    //
  },
};
</script>
<style lang="scss" scoped>
//
.mcont {
  width: 100vw;
  padding: 3rem 0;
  min-height: 100vh;

  background: url("~@/assets/imgs/ilus/undraw_group_chat_re_frmo.svg"),
    rgba(245, 245, 245, 0.95);
}
</style>
