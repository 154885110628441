<template>
  <div class="vertical centered">
    <home id="Home" />
    <v-divider color="primary" style="width: 80vw"></v-divider>
    <beratung id="Beratung" />
    <v-divider color="primary" style="width: 80vw"></v-divider>
    <planung id="Planung" />
    <v-divider color="primary" style="width: 80vw"></v-divider>
    <projects id="Projekte" />
    <v-divider color="primary" style="width: 80vw"></v-divider>
    <we id="We" />
    <v-divider color="primary" style="width: 80vw"></v-divider>
    <contact id="Contact" />
  </div>
</template>
<script>
import Home from "./Home.vue";
import Beratung from "./Beratung.vue";
import Planung from "./Planung.vue";
import Projects from "./Projects.vue";
import We from "./We.vue";
import Contact from "./Contact.vue";
export default {
  //
  components: { Home, Beratung, Planung, Projects, We, Contact },
};
</script>
<style lang="scss" scoped>
//
</style>
