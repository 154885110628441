<template>
  <div class="mcont background vertical centered gapped">
    <!--  -->
    <div class="horizontal centered" style="gap: 5%">
      <div
        class="vertical left gapped"
        style="
          min-width: 50vw !important;
          text-align: left;
          margin-right: -5%;
          padding: 0 10%;
        "
      >
        <h1 style="margin: -1rem 0">Planung</h1>
        <h2>Besser planen – höherer Ertrag</h2>
        <!-- <h3>Annannass</h3> -->
        <p>
          Optimale Sonneneinstrahlung, die Platzierung der Module, geringe
          Verluste, passende Speicherauslegung – um das und viel mehr kümmern
          wir uns bei der Planung Ihrer Anlage. Wir nutzen modernste Software
          für die Berechnung und Simulation. Dieses bildet die Grundlage für die
          Wirtschaftlichkeitsberechnung, mit der Sie den Erfolg Ihrer
          Investition ideal beurteilen können.
        </p>
        <h3>Daten als Basis</h3>
        <p>
          Planung braucht Daten – wir erzeugen sie. Unser Aufmaß Ihres Objektes
          bringt die Daten, die wir zur Berechnung nutzen. Dabei nutzen wir
          neben einer Begehung vor Ort auch Satellitenaufnahmen und Drohnenflüge
          zur detaillierten Vermessung.
        </p>
        <!-- Sie können unseren Online-Fragebogen
          nutzen, in dem wir einige grundlegende Dinge erfragen, die zu einer
          ersten Beurteilung Ihres Projektes dienen. -->
      </div>
      <v-img class="big-picture" :src="svg" contain />
    </div>
  </div>
</template>
<script>
import svg from "@/assets/imgs/ilus/undraw_scrum_board_re_wk7v.svg";
export default {
  props: [],
  data() {
    return {
      //
      svg: svg,
    };
  },
  methods: {
    //
  },
  computed: {
    //
  },
};
</script>
<style lang="scss" scoped>
//
.mcont {
  width: 100vw;
  padding: 5rem 0;
  min-height: 100vh;

  background: url("~@/assets/imgs/ilus/undraw_scrum_board_re_wk7v.svg"),
    rgba(245, 245, 245, 0.95);
}
</style>
