<template>
  <div class="mcont background vertical centered gapped">
    <!--  -->
    <div class="horizontal centered" style="gap: 5%">
      <div
        class="vertical left gapped"
        style="
          min-width: 50vw !important;
          text-align: left;
          margin-right: -5%;
          padding: 0 10%;
        "
      >
        <h1 style="margin: -1rem 0">Projekte</h1>
        <p>
          Schauen Sie sich hier eine Auswahl der Solaranlagen an, die unser Team
          bereits realisiert hat. <br />
          Ihre Anlage kann die nächste sein!
        </p>

        <h2>Solar</h2>
        <div class="horizontal gapped left wrapped" style="flex-direction: row">
          <div
            class="vertical centered"
            style="position: relative"
            v-for="(item, index) in solarPics"
            :key="index"
          >
            <v-img
              width="320"
              max-width="90vw"
              height="320"
              :src="item.src"
              style="border-radius: 2rem; border: 2px solid #2113a6"
              @click="setPic(item)"
            />
            <p>{{ item.title }}</p>
            <v-chip
              color="primary"
              style="
                width: auto;
                position: absolute;
                bottom: 4rem;
                left: 0.75rem;
              "
            >
              {{ item.power }}
            </v-chip>
          </div>
        </div>
        <h2>Speicher</h2>
        <div class="horizontal gapped left wrapped" style="flex-direction: row">
          <div
            class="vertical centered"
            style="position: relative"
            v-for="(item, index) in elsePics"
            :key="index"
          >
            <!-- <h3>Speicher</h3> -->
            <v-img
              width="320"
              max-width="90vw"
              height="320"
              :src="item.src"
              style="border-radius: 2rem; border: 2px solid #2113a6"
              @click="setPic(item)"
            />
            <!-- <p>{{ item.title }}</p> -->
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showPic"
      v-if="selectedPic"
      max-width="500px"
      transition="dialog-transition"
      style="border-radius: 2rem"
    >
      <v-card class="vertical left" style="border-radius: 2rem">
        <v-carousel hide-delimiters v-model="selectedIndex">
          <v-carousel-item
            v-for="(item, index) in allPics"
            :key="index"
            :src="item.src"
          >
            <v-chip
              style="margin: 1rem; width: auto"
              color="primary"
              v-if="selectedPic.title"
            >
              {{ selectedPic.title }} - {{ selectedPic.power }}
            </v-chip>
          </v-carousel-item>
        </v-carousel>
        <v-btn
          text
          width="100%"
          color="primary"
          @click="setPic(null)"
          style="padding: 1.5rem 0"
        >
          Schließen
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import svg from "@/assets/imgs/ilus/undraw_asset_selection_re_k5fj.svg";
import Pics from "@/infos/PicsSrc.json";
export default {
  props: [],
  data() {
    return {
      //
      svg: svg,
      selectedPic: null,
      showPic: false,
    };
  },
  methods: {
    //
    setPic(item) {
      if (item) {
        this.selectedPic = item;
        this.showPic = true;
      } else {
        this.showPic = false;
        this.selectedPic = null;
      }
    },
  },
  computed: {
    //
    allPics() {
      return this.solarPics.concat(this.elsePics);
    },

    selectedIndex: {
      get() {
        return this.allPics.indexOf(this.selectedPic);
      },
      set(index) {
        this.selectedPic = this.allPics[index];
      },
    },

    solarPics() {
      Pics.imgs.solar.forEach((item) => {
        item.src = require("@/assets/imgs/" + item.src);
      });
      return Pics.imgs.solar;
    },

    elsePics() {
      Pics.imgs.else.forEach((item) => {
        item.src = require("@/assets/imgs/" + item.src);
      });
      return Pics.imgs.else;
    },
  },
};
</script>
<style lang="scss" scoped>
//
.mcont {
  width: 100vw;
  padding: 3rem 0;
  min-height: 100vh;

  background: url("~@/assets/imgs/ilus/undraw_asset_selection_re_k5fj.svg"),
    rgba(245, 245, 245, 0.95);
}
</style>
