import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true,
      },
      light: {
        micom: "#77003B",
        primary: "#2113A6",
        secondary: "#DD0307",
        accent: "#DD0307",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        micom: "#77003B",
        primary: "#C6C6C6",
        secondary: "#DD0307",
        accent: "#DD0307",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
